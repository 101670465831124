export const profile = "bauhaus"

export const modules = {
  showSalesman: false,
  showAdditionalPosts: false,
  showPostOffsets: false,
  showWallHeight: false,
  showSupport: true,
  showSideAwning: false,
  showFullFabricBrand: false,
  showRadiantHeater: false,
  showPromoCodes: true,
  showDeliveryPreferences: true,
  showDiscount: false,
  showStatic: false,
  editStatus: false,
  showSalesPrice: true
}

export const options = {
  additionalRoofingOptions: [],
  additionalColorOptions: [],
  awningPositionOptions: [
    "roof",
  ],
  sidePanelVariants: [
    { "value": null, "text": "Bitte Wählen", "disabled": false },
    { "value": "ganzglas_schiebeanlage", "text": "Ganzglas Schiebeanlage", "text_seite": "Keil + Ganzglas Schiebeanlage" },
    { "value": "festelement", "text": "Festelement", "text_seite": "Keil + Festelement" },
    { "value": "dreieck", "text": "Keil" }
  ],
  panelOpt: [
    { "value": null, "text": "Bitte wählen" },
    { "value": "verglasung", "text": "Bodentiefe Verglasung" }
  ],
  additionalLightingOptions: [],
  paymentOptions: [
    { "value": null, "text": "Bitte wählen", "disabled": true },
    { "value": "bm1", "text": "BM 1: 100% bei Auftragsbestätigung" },
    { "value": "bm2", "text": "BM 2: 90% bei Auftragsbestätigung, 10% nach Lieferung" },
    { "value": "bm3", "text": "BM 3: 80% bei Auftragsbestätigung, 20% nach Lieferung" },
    { "value": "bm4", "text": "BM 4: 70% bei Auftragsbestätigung, 30% nach Lieferung" },
    { "value": "bm5", "text": "BM 5: 60% bei Auftragsbestätigung, 40% nach Lieferung" },
    { "value": "bm6", "text": "BM 6: 50% bei Auftragsbestätigung, 50% nach Lieferung" },
    { "value": "bm7", "text": "BM 7: 40% bei Auftragsbestätigung, 60% nach Lieferung" },
    { "value": "bm8", "text": "BM 8: 30% bei Auftragsbestätigung, 70% nach Lieferung" },
    { "value": "bm9", "text": "BM 9: 20% bei Auftragsbestätigung, 80% nach Lieferung" },
    { "value": "bm10", "text": "BM 10: 10% bei Auftragsbestätigung, 90% nach Lieferung" },
    { "value": "bm11", "text": "BM 11: 100% nach Lieferung" }
  ],
  statusOptions: [
    { id: "sentToManufacturer", title: "Auftrag an Hersteller versendet" }
  ],
  typeOptions: [
    {id: "roof", title: "Terrassendach"},
    {id: "carport", title: "Carport"}
  ],
}

export const config = {
  minDepth: 1200,
  minWidth: 2000,
  maxWidth: 7000,
  awningDepth: 5000,
  defaultAwningWidth: true,
  retrievalRegex: /^9\d{9}$/
}

export function steps(step: string) {
  return [
    "CustomerInformation",
    "SolarCarport",
    "GeneralData",
    "Awning",
    "Lighting",
    "SidePanels",
    "Payment",
    "Summary"
  ].includes(step)
}

export const logoSrc = "/img/icons/solid-elements-logo.png"
export const awningTitle = "Markise (Ansicht vom Garten zur Hauswand)"
export const retrievalNumber = "Abrufnummer"

export const authConfig = {
  VUE_APP_KEYCLOAK_URL: "https://keycloak.cekom.de",
  VUE_APP_KEYCLOAK_REALM: "ldvsg_bauhaus"
}